<template>
  <div>
    <Formular />
    <div></div>
  </div>
</template>

<script>
import Formular from "./components/Formular.vue";

export default {
  name: "App",
  components: {
    Formular,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #e50009;*/
  margin-top: 60px;
}
</style>
